.container{
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 200px;
    width: 800px;
    background-color: #fff;
    padding-bottom: 30px;
}

.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 30px;
}
.text{
    color: #3c009d;
    font-size: 25px;
    font-weight: 700;
}

.underline{
    width: 180px;
    height: 6px;
    background: #3c009d;
    border-radius: 9px;
}
.inputs{
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    gap: 25px;

}
.input{
    display: flex;
    align-items: center;
    margin: auto;
    width: 480px;
    height: 80px;
    background: #eaeaea;
    border-radius: 6px;
}
.input img{
    margin: 0px 30px;
}

.input input{
   height: 50px;
   width: 400px;
   background: transparent;
   border: none;
   outline: none;
   color: #797979;
   font-size: 20px; 
}
.forgot-password{
    padding: 62px;
    margin-top: 27px;
    color: #797979;
    font-size: 19px;
    font-style: italic;
}

.forgot-password span{
    color: #4c00b4;
    cursor: pointer;
    font-style: normal;
    display: block;
    #font-weight: bold;
}
.submit-container{
    display: flex;
    gap: 20px;
    margin: 60px auto;
}
.submit{
   display: flex;
   justify-content: center;
   align-items: center;
   width: 125px;
   height: 60; 
   color: #fff;
   background: #4c00b4;
   border-radius: 50px;
   font-size: 20px;
   font-weight: 700 px;
   cursor: pointer;
}
.gray{
    background: #EAEAEA;;
    color: #676767;
}